import { inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from 'apps/cambix/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  private meta = inject(Meta);

  addNoIndexMetaTag() {
    if (!environment.production) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
      this.meta.addTag({ name: 'googlebot', content: 'noindex, nofollow' });
    }
  }
}
