/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { MigrationHttpModule } from './http.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'apps/cambix/src/environments/environment';
import { SmsCodeRes, SmsCodeResV3 } from '../interfaces/changePass.response.interface';
import {
  IupdateGoogleRequest,
  SmsCodeReqV3,
  ValidateSmsCodeReqV3,
} from '../interfaces/changePass.request.interface';

@Injectable({
  providedIn: MigrationHttpModule,
})
export class MigrationChangePassHttp {
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.api_url}`;
  }

  sendSmsCode(cellphone: string): Observable<SmsCodeResV3> {
    const smsCodeReq: SmsCodeReqV3 = {
      cellphone,
    };
    return this.http.post<SmsCodeResV3>(`${this.apiUrl}v3/auth/password/sms`, smsCodeReq);
  }
  resendSMSCode(token: string): Observable<SmsCodeRes> {
    const params = {
      token,
    };
    return this.http.post<SmsCodeRes>(`${this.apiUrl}v3/validations/sms/resend`, params);
  }
  validateSmsCode(code: string, token: string) {
    const params: ValidateSmsCodeReqV3 = {
      code,
      token,
    };
    return this.http.post(`${this.apiUrl}v3/validations/sms/check`, params);
  }

  updateDataUserMigrationWithGoogle(updateGoogleRequest: IupdateGoogleRequest): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}v2/user-leads/user-lead`, updateGoogleRequest);
  }

  updateDataUserMigration(data: any): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}v5/users/password`, data);
  }
}
