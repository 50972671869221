/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from 'apps/cambix/src/environments/environment';
import { SmsCodeResV3, TokenRes, Ijwt } from '../../interface/token/token.response';
import { ProfileUserHttp } from 'apps/cambix/src/app/cambix/dashboard/profile-user/http/profile-user.service';
import { IProfileUser } from 'apps/cambix/src/app/cambix/dashboard/profile-user/interface/profile.interface';
import { StorageService } from '../storage/storage.service';

import { STORAGE_KEY } from '../../constants/storage';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private _apiUrl = environment.api_url;
  path: string;

  constructor(
    private http: HttpClient,
    private profileService: ProfileUserHttp,
    private storageService: StorageService,
  ) {
    this.path = 'v2/auth/refresh-token';
  }

  onValidateFormEmail(email: string) {
    let validateEmail: boolean;
    if (email) {
      const regex = /(\.{2,}|\.+$)/;
      const dotCount = email.split('.').length - 1;
      if (dotCount > 2 || regex.test(email)) {
        validateEmail = true;
      }
    }

    return validateEmail;
  }

  onUpdateDateStorage() {
    const datosSession: any = this.getStoreSessionUser();
    let sesionUsuario = JSON.parse(datosSession);
    //Chagen token type user
    this.storeToken(sesionUsuario.token);
    return this.profileService.getDataUserToken();
  }

  refreshToken() {
    return this.http
      .post<any>(`${environment.api_url}${this.path}`, {
        refreshToken: this.getRefreshToken(),
      })
      .pipe(
        tap((tokenRes: TokenRes) => {
          this.storeTokens(tokenRes);
        }, catchError(this.formatErrors)),
      );
  }

  getToken(): any {
    return this.storageService.getData(STORAGE_KEY.authToken) || null;
  }

  getRefreshToken() {
    return this.storageService.getData(STORAGE_KEY.refreshAuthToken) || null;
  }

  storeToken(token: string) {
    this.storageService.setData(STORAGE_KEY.authToken, token);
  }

  storeTokens(tokenRes: TokenRes): void {
    this.storageService.setData(STORAGE_KEY.authToken, tokenRes.token);
    this.storageService.setData(STORAGE_KEY.refreshAuthToken, tokenRes.refreshToken);
  }

  removeToken() {
    this.storageService.removeData(STORAGE_KEY.authToken);
  }

  getStoreSessionUser() {
    return this.storageService.getData(STORAGE_KEY.sessionUser) || null;
  }

  setStoreSessionUser(data: IProfileUser) {
    data.profile = this.setProfileSession(data);
    this.storageService.setData(STORAGE_KEY.sessionUser, JSON.stringify(data));
  }

  setProfileSession(data: IProfileUser): string[] {
    const profiles: string[] = [];
    // SI ERES PERSONA
    if (data.userType === 'PER') {
      if (data.hasCompany) {
        profiles.push('persona', 'empresa');
      } else {
        profiles.push('persona');
      }
    }

    // SI ERES EMPRESA
    if (data.userType === 'EMP') {
      if (data.hasUser) {
        profiles.push('persona', 'empresa');
      } else {
        profiles.push('empresa');
      }
    }

    return profiles;
  }

  removeStoreSessionUser() {
    this.storageService.removeData(STORAGE_KEY.sessionUser);
  }

  getStoreSessionSimulator() {
    return this.storageService.getData(STORAGE_KEY.sessionSimulator) || null;
  }

  setStoreSessionSimulator(data: any) {
    this.storageService.setData(STORAGE_KEY.sessionSimulator, JSON.stringify(data));
  }

  removeStoreSessionSimulator() {
    this.storageService.removeData(STORAGE_KEY.sessionSimulator);
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  getSms(): SmsCodeResV3 | null {
    const storageSmsRes = sessionStorage.getItem('sms-response');
    if (storageSmsRes) {
      const smsCodeRes = JSON.parse(storageSmsRes) as SmsCodeResV3;
      return smsCodeRes;
    }
    return null;
  }

  get isAuthenticated(): boolean {
    const datosSession: any = this.getStoreSessionUser();
    const user = datosSession ? JSON.parse(datosSession) : null;
    const token = this.getToken();
    return token && user;
  }

  toggleUserProfile(): Observable<IProfileUser> {
    let oldTokens: Ijwt = {
      jwt: {
        token: this.getToken(),
        refreshToken: this.getRefreshToken(),
      },
    };

    return this.http.post<Ijwt>(this._apiUrl + 'v1/users/jwt', null).pipe(
      catchError((e) => {
        this.storeTokens(oldTokens.jwt);
        return of(e);
      }),
      tap((jwtTokens: Ijwt) => {
        const { jwt } = jwtTokens;
        this.storeTokens(jwt);
      }),
      switchMap((jwt) => this.http.get<IProfileUser>(this._apiUrl + 'v2/users/me')),
      tap((userData) => {
        this.setStoreSessionUser(userData);
      }),
    );
  }
}
